import React from 'react';
import {
  Box,
  Stack,
  Typography,
  Button,
  BoxProps,
  StackProps,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { isMobile, SafeAreaGaps } from '../../helper';

const contentContainerHeight = isMobile ? '100vh' : 'calc(100vh - 184.97px)';

const buttonContainerHeight = '56.5px';

const containerStyles: BoxProps['sx'] = {
  minHeight: '100vh',
  overflowY: 'auto',
  background: {
    xs: 'none',
    sm: 'none',
    md: 'none',
    lg: `url('/images/backgrounds/bg-lg.png') center/101% 100%`,
  },
  p: {
    xs: '15px',
    lg: 'unset',
  },
};

const contentContainerStyles: BoxProps['sx'] = {
  backgroundColor: 'background.default',
  minHeight: contentContainerHeight,
  mt: {
    lg: '-50px',
  },
};

const subContentContainerStyles: BoxProps['sx'] = {
  display: 'flex',
  p: { xs: '15px', lg: '15px 100px' },
  justifyContent: 'center',
  mb: '10px',
};

const buttonContainerStyles: StackProps['sx'] = {
  position: {
    xs: 'fixed',
    sm: 'static',
  },
  left: 0,
  bottom: SafeAreaGaps.bottom,
  backgroundColor: 'background.default',
  width: { xs: '100%', sm: 'unset' },
  p: '10px 10px',
};

const mobileImageContainerStyles: StackProps['sx'] = {
  backgroundColor: 'background.default',
  position: 'fixed',
  top: SafeAreaGaps.top,
  width: '100%',
  left: 0,
  p: '15px 0',
  display: { xs: 'flex', lg: 'none' },
  zIndex: 1,
};

const buttonGapStyles: BoxProps['sx'] = {
  display: { xs: 'block', sm: 'none' },
  minHeight: buttonContainerHeight,
};

const PaidTrialClass: React.FC = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const trialId = params.get('id');

  return (
    <Box sx={containerStyles}>
      <Stack
        direction="row"
        justifyContent="center"
        sx={mobileImageContainerStyles}
      >
        <img
          src="/images/logos/tp-logo-green.png"
          alt="tp-logo"
          width="120px"
        />
      </Stack>
      <Box sx={{ minHeight: '65px', display: { xs: 'block', lg: 'none' } }} />
      {!isMobile && (
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ display: { xs: 'none', lg: 'flex' } }}
        >
          <img
            src="/images/logos/tp-logo-text-white.png"
            alt="tp-logo"
            style={{ width: '150px', marginTop: '20px' }}
          />
          <img
            src="/images/shapes/wave.png"
            alt="wave-shape"
            style={{ width: '100%', marginTop: '20px', marginBottom: '-2px' }}
          />
        </Stack>
      )}
      <Box sx={contentContainerStyles}>
        <div style={{ textAlign: 'center' }}>
          <Typography variant="h5" fontWeight={600}>
            You&apos;re all set
          </Typography>
        </div>
        <Box sx={subContentContainerStyles}>
          <img
            src="/images/avatars/paidTrial.png"
            alt="tp-logo"
            style={{ width: '250px', marginTop: '10px' }}
          />
        </Box>
        {/* BUTTON CONTAINER */}
        <Box sx={buttonGapStyles} />
        {!isMobile && (
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={buttonContainerStyles}
          >
            <Button
              component="a"
              fullWidth
              variant="contained"
              color="primary"
              style={{ maxWidth: '300px', margin: '10px' }}
              sx={{
                backgroundColor: 'primary.main',
                '&:hover': {
                  backgroundColor: 'primary.main',
                },
              }}
              className="onboarding-paid-trial-confirmation"
              href={`${process.env.REACT_APP_BASE_URL}/onboarding/complete/trial-class-details?id=${trialId}`}
            >
              Confirm class with S$ 0.50
            </Button>
            <Button
              component="a"
              fullWidth
              disableElevation
              variant="contained"
              color="inherit"
              sx={{ backgroundColor: 'transparent' }}
              style={{ maxWidth: '300px' }}
              href={`${process.env.REACT_APP_BASE_URL}/onboarding/complete/trial-class-details?id=${trialId}`}
            >
              Skip
            </Button>
          </Stack>
        )}
      </Box>
    </Box>
  );
};

export default PaidTrialClass;
